<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        variant="primary"
        pill
        @click="createProcedure"
        v-if="hasPermission('create_procedure')"
      >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div>

    <b-card>
      <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Procedure Name"
              />
            </b-form-group>
            <b-form-group
              label="Hospital"
              label-for="hospital"
              class="w-50"
              v-if="searchType.value === 2"
            >
              <v-select
                id="hospital"
                v-model="selectedHospital"
                :options="hospitals"
                placeholder="Select Hospital"
                label="name"
              />
            </b-form-group>

            <b-form-group
              label="Department"
              label-for="department"
              class="w-50"
              v-if="searchType.value === 3"
            >
              <v-select
                id="department"
                v-model="selectedDepartment"
                :options="departments"
                placeholder="Select Department"
                label="name"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="procedures"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('read_procedure')"
        :per-page="0"
      >
        <template #cell(serial)="row">
          {{ (currentPage - 1) * perPage + row.index + 1 }}
        </template>
        <template #cell(name)="row">
          {{ truncate(row.item.name, 20) }}
        </template>
        <template #cell(hospital)="row">
          {{ truncate(row.item.hospital_name, 20) }}
        </template>

        <template #cell(department)="row">
          {{ truncate(row.item.department_name, 20) }}
        </template>

        <template #cell(created_at)="row">
          {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
          {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
          {{ row.item.created_by }}
        </template>
        <template #cell(updated_by_data)="row">
          {{ row.item.updated_by }}
        </template>
        <template #cell(manage)="row">
          <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editProcedure(row.item)"
            v-if="hasPermission('update_procedure')"
          >
            Edit
          </b-button>
          <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeProcedure(row.item)"
            v-if="hasPermission('delete_procedure')"
          >
            Delete
          </b-button>
        </template>
      </b-table>
      <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
      ></b-pagination>
    </b-card>
    <procedure-create-modal
      @modalClosed="onModalClosed"
      :key="`create-${procedureCreateModalCount}`"
    />
    <procedure-edit-modal
      :procedure="procedure"
      @modalClosed="onModalClosed"
      :key="`edit-${procedureEditModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProcedureCreateModal from "@/components/admin/procedure/ProcedureCreateModal.vue";
import ProcedureEditModal from "@/components/admin/procedure/ProcedureEditModal.vue";
import util from "@/util.js";

export default {
  components: {
    ProcedureCreateModal,
    ProcedureEditModal,
  },
  data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "hospital", label: "hospital" },
        { key: "department", label: "department" },
        { key: "amount", label: "amount" },
        { key: "duration", label: "duration" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Updated At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      procedures: [],
      procedure: null,
      ProcedureCreateModal: 0,
      ProcedureEditModal: 0,
      procedureCreateModalCount: 0,
      procedureEditModalCount: 0,
      searchTypes: [
        { value: 1, name: "Name" },
        { value: 2, name: "Hospital" },
        { value: 3, name: "Department" },
      ],
      searchType: null,
      name: "",
      selectedHospital: null,
      hospitals: [],
      selectedDepartment: null,
      departments: [],
    };
  },
  mixins: [util],
  created() {
    this.searchType = this.searchTypes[0];
  },
  async mounted() {
    await this.fetchPaginatedData();
    await this.fetchHospitals();
    await this.fetchDepartments();
  },
  methods: {
    ...mapActions({
      getProcedures: "appData/getProcedures",
      deleteProcedure: "appData/deleteProcedure",
      getHospitalsPublic: "appData/getHospitalsPublic",
      getDepartmentsPublic: "appData/getDepartmentsPublicNav",
    }),
    async search() {
      if (this.searchType) {
        switch (this.searchType.value) {
          case 1:
            this.selectedHospital = null;
            this.selectedDepartment = null;
            break;
          case 2:
            this.name = "";
            this.selectedDepartment = null;
            break;
          case 3:
            this.name = "";
            this.selectedHospital = null;
            break;
        }
      } else {
        this.name = "";
        this.selectedHospital = null;
        this.selectedDepartment = null;
      }
      this.currentPage = 1;
      await this.fetchPaginatedData();
    },
    async fetchHospitals() {
      try {
        const res = await this.getHospitalsPublic();
        this.hospitals = res.data;
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    },
    async fetchDepartments() {
      try {
        const res = await this.getDepartmentsPublic();
        this.departments = res.data;
      } catch (error) {
        console.error("Error fetching hospitals:", error);
      }
    },
    async fetchPaginatedData() {
      try {
        const res = await this.getProcedures({
          pageNumber: this.currentPage,
          name: this.name,
          hospital: this.selectedHospital ? this.selectedHospital.id : null,
          department: this.selectedDepartment
            ? this.selectedDepartment.id
            : null,
        });
        this.procedures = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },
    createProcedure() {
      this.procedureCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("procedure-create-modal");
      });
    },
    editProcedure(procedure) {
      this.procedure = procedure;
      this.procedureEditModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("procedure-edit-modal");
      });
    },
    async removeProcedure(procedure) {
      this.procedure = procedure;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteProcedure({
              pk: this.procedure.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: "Procedure deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            console.error("Error deleting procedure:", error);
          }
        }
      });
    },
    async onModalClosed() {
      await this.fetchPaginatedData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
    rows() {
      return this.procedures.length;
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
    searchType: function (newVal, oldVal) {
      if (newVal && oldVal && newVal.value !== oldVal.value) {
        this.name = "";
        this.selectedHospital = null;
        this.selectedDepartment = null;
      }
    },
  },
};
</script>

<style>
.bg1 {
  color: red;
  font-weight: normal;
}
</style>
